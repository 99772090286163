import React, { useState } from 'react';

import { useDealerOrders } from '../../hooks/use-dealer-orders';
import Breadcrumb from '../Breadcrumb';
import Section from '../Section';
import AccountLayout from '../account/AccountLayout';
import MainLayout from '../layout/MainLayout';
import OrderEmpty from '../orders/OrderEmpty';
import OrderTable from '../orders/OrderTable';
import BasePagination from '../pagination/BasePagination';

const DealerOrderHistoryPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const { orders, total, pageSize, isOrdersLoading } = useDealerOrders({
    page: currentPage,
  });

  const onChangePage = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <MainLayout>
      <Breadcrumb
        items={[
          {
            title: 'คำสั่งซื้อดีเลอร์',
            link: '/dealer-orders',
          },
        ]}
        title="คำสั่งซื้อดีเลอร์"
      />
      <Section className="py-6">
        <AccountLayout>
          <div className="rounded-[6px] border border-light-divider bg-neutral-1 p-6 shadow-small">
            {orders.length > 0 || isOrdersLoading ? (
              <>
                <OrderTable
                  loading={isOrdersLoading}
                  orders={orders}
                  orderPath="/dealer-orders"
                />
                <BasePagination
                  total={total}
                  pageSize={pageSize}
                  current={currentPage}
                  onChange={onChangePage}
                />
              </>
            ) : (
              <OrderEmpty />
            )}
          </div>
        </AccountLayout>
      </Section>
    </MainLayout>
  );
};

export default DealerOrderHistoryPage;
